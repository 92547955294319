import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import SubHero from "../components/SubHero"
import { graphql } from "gatsby"
import WorkCategories from "../components/WorkCategories"
import WorkRowContainer from "../components/WorkRowContainer"
import WorkRowContainerNew from "../components/WorkRowContainerNew"

export default function MyWorkPage({
  pageContext: { id, projects, projectsNew },
  data,
}) {
  // todo: handle images
  return (
    <Layout>
      <Seo
        title="My Work"
        description="I have evolved
            from creating 3-hour long films to 30-min short films to 2-minute
            commercials to 10-second micro-content on Instagram."
      />
      <SubHero title="My Work" type="work" />
      <WorkCategories id={id} />
      {projectsNew ? (
        <WorkRowContainerNew
          projects={projectsNew}
          images={data.images.edges}
        />
      ) : null}
      {projects ? (
        <WorkRowContainer projects={projects} images={data.images.edges} />
      ) : null}
    </Layout>
  )
}

export const query = graphql`
  query WorkImageQuery($folder: String!) {
    images: allFile(
      filter: { relativeDirectory: { eq: $folder } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
