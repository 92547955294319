import React, { useEffect } from "react"
import WorkRow from "./WorkRow"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const WorkRowContainer = ({ projects, images }) => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)
      gsap.core.globals("ScrollTrigger", ScrollTrigger)
    }

    gsap.utils.toArray(".workRow").forEach(container => {
      let image = container.querySelector(".workRow__image")
      let h1 = container.querySelector("h1")
      let h2 = container.querySelector("h2")
      let p = container.querySelectorAll("p")
      let icon = container.querySelector(".awardIcon")

      let workRowTl = gsap
        .timeline({
          scrollTrigger: {
            trigger: container,
            start: "top 70%",
          },
        })
        .from(image, {
          opacity: 0,
          duration: 0.5,
        })
      if (icon) {
        workRowTl.from(icon, {
          opacity: 0,
          duration: 0.1,
        })
      }
      workRowTl
        .from(h1, {
          y: 30,
          duration: 0.25,
          opacity: 0,
        })
        .from(
          h2,
          {
            x: 20,
            duration: 0.25,
            opacity: 0,
          },
          ">-0.1"
        )
        .from(p, {
          y: 20,
          duration: 0.15,
          opacity: 0,
          stagger: 0.25,
        })
    })
  }, [])

  return (
    <section className="myWorkContainer">
      {projects.map((project, i) => (
        <WorkRow
          {...project}
          inverse={i % 2 ? true : false}
          key={i}
          image={images[i].node.childImageSharp.fluid}
        />
      ))}
    </section>
  )
}

export default WorkRowContainer
