import React from "react"
import { Link } from "gatsby"

const categoryData = [
  {
    name: "Tiktok",
    slug: "/portfolio/tiktok",
  },
  {
    name: "Youtube",
    slug: "/portfolio/youtube",
  },
  {
    name: "Films",
    slug: "/portfolio/films",
  },
  {
    name: "Ads",
    slug: "/portfolio/ads",
  },
  {
    name: "Music Videos",
    slug: "/portfolio/music-videos",
  },
  {
    name: "Social Causes",
    slug: "/portfolio/social-causes",
  },
]

const WorkCategories = ({ id }) => {
  return (
    <section className="categories">
      <div className="categories__container">
        {categoryData.map((c, i) => (
          <Link to={c.slug} className="category" key={c.name}>
            <div
              className={
                i === id ? `category__title active` : `category__title`
              }
            >
              {c.name}
            </div>
          </Link>
        ))}
      </div>
    </section>
  )
}

export default WorkCategories
