import React, { useEffect } from "react"
import BigPicture from "bigpicture"
import { AiOutlineEye } from "react-icons/ai"
import { GrPlayFill } from "react-icons/gr"
import Img from "gatsby-image"
import { GiTrophyCup } from "react-icons/gi"

const WorkRow = ({
  id,
  title,
  role,
  views,
  linkCode,
  image,
  inverse,
  award,
}) => {
  useEffect(() => {
    let els = document.querySelectorAll(`.watch--${id}`)
    els.forEach(el => {
      el.addEventListener("click", () => {
        BigPicture({
          el: el,
          ytSrc: linkCode,
        })
      })
    })
  }, [id, linkCode])

  return (
    <div className={inverse ? `workRow inverse` : `workRow`}>
      <div
        className={`workRow__image watch--${id}`}
        style={{ cursor: "pointer" }}
      >
        <Img fluid={image} alt={title} />
      </div>
      <div className="workRow__content">
        {award && <GiTrophyCup className="awardIcon" />}
        <h2>{role}</h2>
        <h1>{title}</h1>
        <div className="workRow__content--cta">
          <p className={`watch--${id}`} style={{ cursor: "pointer" }}>
            <GrPlayFill style={{ fontSize: "1.15rem" }} /> Watch Video
          </p>
          {views && (
            <p>
              <AiOutlineEye /> {views}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default WorkRow
