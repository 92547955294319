import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const MyBtn = () => (
  <div className="workRowNew__content--cta">
    <p style={{ cursor: "pointer" }}>Watch Video</p>
  </div>
)

const WorkRowNew = ({ brand, industry, works, images }) => {
  return (
    <div className="workRowNew">
      <div className="workRowNew__content">
        <h2>{industry}</h2>
        <h1>{brand}</h1>
      </div>
      <div className="workRowNew__container">
        {works.map((work, i) => (
          <div className="workRowNew__col" key={i}>
            <Link
              to={work.link}
              style={{ textDecoration: "none" }}
              target="_blank"
            >
              <div className="workRowNew__image" style={{ cursor: "pointer" }}>
                <Img
                  fluid={images[i].node.childImageSharp.fluid}
                  alt="aishwarya-soni"
                />
              </div>
              <MyBtn />
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default WorkRowNew
