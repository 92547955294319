import React from "react"
import WorkRowNew from "./WorkRowNew"

const WorkRowContainerNew = ({ projects, images }) => {
  const data = projects.map((project, i) => {
    const toReturn = { ...project, images: [] }
    if (i === 0) {
      toReturn.images = images.slice(0, 4)
    } else {
      toReturn.images = images.slice(4, 16)
    }
    return toReturn
  })

  return (
    <section className="myWorkContainer">
      {data.map((project, i) => {
        return <WorkRowNew {...project} key={i} />
      })}
    </section>
  )
}

export default WorkRowContainerNew
